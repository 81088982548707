import {rmApp} from "../../clients/app";

rmApp.createApp({
    data: {
        activeArea: 0
    },
    mounted: function() {
        let url = document.URL;
        let split = url.split("#");
        if (split.length > 1) {
            let anchor = split[1];
            let anchorSplit = anchor.split("|");
            this.activeArea = parseInt(anchorSplit[0]);
            let company = anchorSplit.length > 1 ? anchorSplit[1] : null;
            let scrollTo = company ? document.getElementById(this.activeArea+"|"+company) : this.$refs.tabs;
            let offset = company ? -10 : 10;
            this.$smoothScroll({
                scrollTo: scrollTo,
                duration: 500,
                offset: offset,
                updateHistory: false
            });
        }
    }
});